import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const QuestionMark = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
            ? props.colour
            : theme.colours.white
        }
        fillRule="evenodd"
        d="M16.337 10c0 2.6-3.3 3.8-3.7 3.9-.1.1-.2.1-.3.1-.4 0-.8-.3-.9-.7-.2-.5.1-1.1.6-1.3.6-.2 2.3-1 2.3-2.1 0-.9-.5-1.6-1.3-1.9-1-.4-2.2.2-2.6 1.2-.2.5-.8.8-1.3.6-.5 0-.7-.6-.6-1.1.7-2.1 3-3.2 5.1-2.4 1.6.5 2.7 2 2.7 3.7zm7.1 2c0 6.1-4.9 11-11 11s-11-4.9-11-11 4.9-11 11-11 11 4.9 11 11zm-2 0c0-5-4-9-9-9s-9 4-9 9 4 9 9 9 9-4 9-9zm-8.3 4.3s-.1-.1-.2-.1-.1-.1-.2-.1-.1 0-.2-.1c-.2 0-.4 0-.6.1-.1 0-.2.1-.3.2-.1.1-.2.2-.2.3 0 .1-.1.2-.1.4 0 .3.1.5.3.7.2.2.4.3.7.3.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.1 0-.3-.1-.4.1-.1 0-.2-.1-.3z"
      />
    </svg>
  )
}

export default QuestionMark
