import * as React from 'react'
import styled from 'styled-components'
import NewsletterForm from './newsletter-form'
import NewsletterConfirmation from './newsletter-confirmation'

interface Props {
  onClose: () => void
}

const NewsletterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  div {
    text-align: center;
  }
`

const NewsletterModal = (props: Props): JSX.Element => {
  const [isSubscribed, setSubscribed] = React.useState(false)

  const closeModal = (): void => {
    setSubscribed(true)
  }

  return (
    <NewsletterContainer>
      {isSubscribed ? (
        <NewsletterConfirmation onClose={props.onClose} />
      ) : (
        <NewsletterForm onSubscribe={closeModal} />
      )}
    </NewsletterContainer>
  )
}

export default NewsletterModal
