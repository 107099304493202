import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  color?: string
}

const TwitterIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.color
            ? props.color
            : theme.colours.white
        }
        fillRule="evenodd"
        d="M24.012 2.189c-.3-.201-.798-.201-1.098 0-.698.503-1.496.905-2.295 1.207-1.995-1.81-5.089-1.911-7.184 0-1.297 1.005-1.996 2.514-1.996 4.123-2.894-.201-5.488-1.71-7.185-4.123-.2-.302-.498-.403-.898-.403-.399 0-.698.302-.798.604-.1.1-1.098 2.514-.998 5.43.1 2.514 1.098 5.732 4.79 8.045-1.497.704-3.193 1.006-4.89 1.006-.399 0-.898.302-.997.704-.1.402.1.905.499 1.106C3.456 21.296 6.15 22 8.645 22s4.89-.603 7.085-1.91c4.29-2.414 6.686-7.04 6.686-12.571v-.503c.997-1.106 1.696-2.414 1.995-3.822.1-.402-.1-.804-.399-1.005zM20.62 6.01c-.2.201-.299.604-.299.905.1.201.1.403.1.604 0 4.827-2.096 8.749-5.688 10.86-2.794 1.61-6.087 2.012-9.38 1.207 1.297-.402 2.495-.905 3.592-1.71.4-.2.5-.502.5-.904s-.3-.704-.6-.805C3.058 13.553 3.258 8.625 3.856 6.111c2.196 2.212 5.289 3.52 8.582 3.419.499 0 .998-.503.998-1.006V7.52c0-1.006.399-2.012 1.197-2.716 1.397-1.307 3.692-1.106 4.89.302.3.302.698.402.998.302.2-.1.499-.1.698-.201-.2.301-.4.503-.599.804z"
      />
    </svg>
  )
}

export default TwitterIcon
