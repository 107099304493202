import { ReactNode } from 'react'
import * as React from 'react'
import Container from './container'
import Navigation from './organisms/navigation'
import styled from 'styled-components'

import './base.css'
import theme from './atoms/theme'
import Footer from './molecules/footer'

interface LayoutProps {
  children: ReactNode
  currentPath?: string
}

const Body = styled.div`
  padding: 9rem 0 0 0;
  width: 100%;
  margin: 0 auto;
  max-width: 144rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 7rem 0 0 0;
  }
`

const Layout = ({ children, currentPath }: LayoutProps): JSX.Element => {
  return (
    <>
      <Navigation currentPath={currentPath} />
      <Container>
        <Body>{children}</Body>
      </Container>
      <Footer />
    </>
  )
}

export default Layout
