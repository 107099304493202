import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface ContainerProps {
  children: ReactNode
}

const ContainerComponent = styled.div`
  margin: 0 auto;
  max-width: 144rem;
  padding: 0 2rem;
`

const Container = ({ children }: ContainerProps): JSX.Element => (
  <ContainerComponent>{children}</ContainerComponent>
)

export default Container
