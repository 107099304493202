import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import ErrorMessage from './error-message'

interface InputProps {
  label?: string
  placeholder: string
  errorMessage?: string
  name?: string
  type?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  subText?: string
}

interface InputStyleProps {
  showError: boolean
}

const Label = styled.label`
  display: block;
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-bottom: 1.6rem;
`

const CustomInput = styled.input`
  border: 2px solid
    ${(props: InputStyleProps): string =>
      props.showError ? theme.colours.danger1 : theme.colours.black};
  border-radius: 0.4rem;
  height: 4rem;
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-left: 1.6rem;
  width: 100%;
  appearance: none;
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::placeholder {
    font-size: 1.6rem;
  }
`

const InputContainer = styled.div`
  flex: 1;
`

const SubText = styled.span`
  font-size: 1.4rem;
  padding-left: 1rem;
`

const Input = (props: InputProps): JSX.Element => {
  return (
    <InputContainer>
      {props.label && (
        <Label>
          {props.label}
          {props.subText && <SubText> {props.subText}</SubText>}
        </Label>
      )}
      <CustomInput
        onChange={props.onChange}
        showError={!!props.errorMessage}
        placeholder={props.placeholder}
        type={props.type || 'text'}
        id={props.name}
        name={props.name}
        onBlur={props.onBlur}
      />
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </InputContainer>
  )
}

export default Input
