import React, { ReactNode } from 'react'
import styled from 'styled-components'
import theme from './theme'

interface Props {
  children: ReactNode
  halfWidth?: boolean
  style?: object
}

interface StyleProps {
  halfWidth: boolean
}

const Row = styled.div`
  display: flex;
  margin-bottom: 4rem;
  width: ${(props: StyleProps): string => (props.halfWidth ? '50%' : '100%')};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
  }
`

const RowContainer = (props: Props): JSX.Element => {
  return (
    <Row halfWidth={props.halfWidth} style={props.style}>
      {props.children}
    </Row>
  )
}

export default RowContainer
