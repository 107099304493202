export interface Theme {
  theme: {
    colours: {
      primary: string
      primaryHover: string
      secondary: string
      secondaryHover: string
      tertiary: string
      tertiaryHover: string
      quaternary: string
      quaternaryHover: string
      danger1: string
      danger1Hover: string
      danger2: string
      danger2Hover: string
      warning1: string
      warning1Hover: string
      warning2: string
      warning2Hover: string
      success1: string
      success1Hover: string
      success2: string
      success2Hover: string
      gray1: string
      gray2: string
      gray3: string
      gray4: string
      gray5: string
      white: string
      black: string
      offBlack: string
    }
    breakpoints: {
      toTinyScreen: string
      toSmallScreen: string
      toMediumScreen: string
      toNormalScreen: string
      toLargeScreen: string
      toXLargeScreen: string
      fromTinyScreen: string
      fromSmallScreen: string
      fromMediumScreen: string
      fromNormalScreen: string
      fromLargeScreen: string
      fromXLargeScreen: string
    }
    fonts: {
      primary: string
      primaryBold: string
      primaryLight: string
      secondary: string
    }
  }
}

export const breakpointValues = {
  toTinyScreen: 400,
  toSmallScreen: 600,
  toMediumScreen: 800,
  toNormalScreen: 1000,
  toLargeScreen: 1200,
  toXLargeScreen: 1450,
  fromTinyScreen: 401,
  fromSmallScreen: 601,
  fromMediumScreen: 801,
  fromNormalScreen: 1001,
  fromLargeScreen: 1201,
  fromXLargeScreen: 1751,
}

export default {
  colours: {
    primary: '#faa21b',
    primaryHover: '#fbb549',
    secondary: '#022B55',
    secondaryHover: '#355577',
    tertiary: '#942a6e',
    tertiaryHover: '#a9558b',
    quaternary: '#f27321',
    quaternaryHover: '#f27321cc',
    danger1: '#ed1941',
    danger1Hover: '#ffecf0',
    danger2: '#ffe7e5',
    danger2Hover: '#ffdbd8',
    warning1: '#ffbe4f',
    warning1Hover: '#e5ab4a',
    warning2: '#fff4e3',
    warning2Hover: '#fff0d5',
    success1: '#3dcc79',
    success1Hover: '#39b86f',
    success2: '#e0f7e9',
    success2Hover: '#d1f3df',
    gray1: '#d6d7d8',
    gray2: '#dedfe0',
    gray3: '#e5e9ee',
    gray4: '#efefef',
    gray5: '#F5FAFF',
    darkGrey: '#272729',
    darkGrey2: '#5C5C5C',
    white: '#ffffff',
    black: '#000000',
    offBlack: '#1c1c1c',
  },
  breakpoints: {
    toTinyScreen: `(max-width: ${breakpointValues.toTinyScreen}px)`,
    toSmallScreen: `(max-width: ${breakpointValues.toSmallScreen}px)`,
    toMediumScreen: `(max-width: ${breakpointValues.toMediumScreen}px)`,
    toNormalScreen: `(max-width: ${breakpointValues.toNormalScreen}px)`,
    toLargeScreen: `(max-width: ${breakpointValues.toLargeScreen}px)`,
    toXLargeScreen: `(max-width: ${breakpointValues.toXLargeScreen}px)`,
    fromTinyScreen: `(min-width: ${breakpointValues.fromTinyScreen}px)`,
    fromSmallScreen: `(min-width: ${breakpointValues.fromSmallScreen}px)`,
    fromMediumScreen: `(min-width: ${breakpointValues.fromMediumScreen}px)`,
    fromNormalScreen: `(min-width: ${breakpointValues.fromNormalScreen}px)`,
    fromLargeScreen: `(min-width: ${breakpointValues.fromLargeScreen}px)`,
    fromXLargeScreen: `(min-width: ${breakpointValues.fromXLargeScreen}px)`,
  },
  fonts: {
    primary: 'proxima-nova, sans-serif',
    primaryLight: 'proxima-nova-light, sans-serif',
    primarySemiBold: 'proxima-nova-semibold, sans-serif',
    primaryBold: 'proxima-nova-bold, sans-serif',
    primaryBlack: 'proxima-nova-black, sans-serif',
    secondary: 'fira-sans, sans-serif',
  },
}
