import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import theme from '../atoms/theme'
import CrossIcon from '../atoms/graphics/cross-icon'
import useWindowSize from '../../hooks/useWindowSize'

Modal.setAppElement('#___gatsby')

const modalStyles = (isMobile: boolean): object => {
  return {
    overlay: {
      backgroundColor: 'rgba(39, 39, 41, 0.2)',
      zIndex: 1000,
    },
    content: {
      opacity: 1,
      position: 'relative',
      top: isMobile ? '10%' : 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      maxWidth: isMobile ? '88%' : '35%',
      margin: '32px auto',
      padding: 0,
      border: 0,
      boxShadow: '0 52px 104px 0 rgba(0, 0, 0, 0.5)',
      maxHeight: '85vh',
    },
  }
}

const CrossIconContainer = styled.div`
  float: right;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  &:hover {
    cursor: pointer;
  }
`

const ContentContainer = styled.div`
  padding: 5.5rem 6.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 5rem 1.5rem 3.6rem;
  }
`

interface Props {
  isOpen: boolean
  children: ReactNode
  onRequestClose: () => void
  contentLabel: string
  style?: object
}

const ModalComponent = (props: Props): JSX.Element => {
  const windowSize = useWindowSize()
  const mobileBreakpoint = theme.breakpoints.toLargeScreen.replace(
    /[^0-9]/g,
    ''
  )
  const isMobile = windowSize.width < parseInt(mobileBreakpoint) ? true : false

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={props.style ? props.style : modalStyles(isMobile)}
      contentLabel={props.contentLabel}
    >
      <CrossIconContainer onClick={props.onRequestClose}>
        <CrossIcon />
      </CrossIconContainer>
      <ContentContainer>{props.children}</ContentContainer>
    </Modal>
  )
}

export default ModalComponent
