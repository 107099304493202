import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const LinkedinIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
            ? props.colour
            : theme.colours.white
        }
        fillRule="evenodd"
        d="M16.437 8c-3.9 0-7 3.1-7 7v7c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-7c0-.6.4-1 1-1s1 .4 1 1v7c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-7c0-3.9-3.1-7-7-7zm5 13h-2v-6c0-1.7-1.3-3-3-3s-3 1.3-3 3v6h-2v-6c0-2.8 2.2-5 5-5s5 2.2 5 5v6zm-15-12h-4c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h4c.6 0 1-.4 1-1V10c0-.6-.4-1-1-1zm-1 12h-2V11h2v10zm-1-19c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
      />
    </svg>
  )
}

export default LinkedinIcon
