import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const InstagramIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
            ? props.colour
            : theme.colours.white
        }
        d="M17.437 1h-10c-3.3 0-6 2.7-6 6v10c0 3.3 2.7 6 6 6h10c3.3 0 6-2.7 6-6V7c0-3.3-2.7-6-6-6zm4 16c0 2.2-1.8 4-4 4h-10c-2.2 0-4-1.8-4-4V7c0-2.2 1.8-4 4-4h10c2.2 0 4 1.8 4 4v10zm-8.2-10c-.5-.1-1-.1-1.5 0-2.7.4-4.6 3-4.2 5.7.4 2.5 2.5 4.3 4.9 4.3.2 0 .5 0 .7-.1 1.3-.2 2.5-.9 3.3-2 .8-1.1 1.1-2.4.9-3.7-.2-2.2-1.9-3.9-4.1-4.2zm1.7 6.7c-.5.6-1.2 1.1-2 1.2-1.6.2-3.2-.9-3.4-2.5-.3-1.6.9-3.2 2.5-3.4h.8c1.3.2 2.3 1.2 2.5 2.5.2.8 0 1.6-.4 2.2zm3.9-7.6c0 .1.1.2.1.4 0 .3-.1.5-.3.7-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.2-.2-.3-.5-.3-.7 0-.1 0-.3.1-.4 0-.1.1-.2.2-.3.4-.4 1-.4 1.4 0 .1.1.2.2.2.3z"
      />
    </svg>
  )
}

export default InstagramIcon
