import React, { ReactNode, ChangeEvent, useState, useEffect } from 'react'
import styled from 'styled-components'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import theme from '../../atoms/theme'
import Button from '../../molecules/button'
import Input from '../../atoms/input'
import isEmail from 'validator/lib/isEmail'
import Row from '../../atoms/row'
import Spinner from '../../atoms/spinner'
import { MAILCHIMP_URL } from '../../constants'

const largeSquiggle = require('../../../assets/large-squiggle-gradient.svg') as string

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
  p {
    margin-bottom: 0;
    text-align: left;
  }
`

const StyledHeader = styled.h2`
  margin-bottom: 2.5rem;
  color: ${theme.colours.secondary};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`

const Squiggle = styled.img`
  width: 13.8rem;
  margin-top: 2.4rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 9rem;
  }
`

const StyledMessage = styled.div`
  color: ${theme.colours.danger1};
`

const Separator = styled.div`
  width: 3rem;
  height: 3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 1.5rem;
    height: 1.5rem;
  }
`
const RequiredFieldLabel = styled.label`
  margin-bottom: 2.4rem;
  display: block;
`

const rowStyles = {
  marginBottom: '1.5rem',
  flexDirection: 'row',
}

const spinnerStyle = {
  height: '3.5rem',
}

const buttonStyle = {
  height: '4.4rem',
  width: '100%',
}

interface FormProps {
  status: string
  message: string
  onSubmitted: (object: object) => void
  onSubscribe: () => void
}

const Form = (props: FormProps): JSX.Element => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })

  const submit = (): void => {
    firstName &&
      lastName &&
      email &&
      email.indexOf('@') > -1 &&
      props.onSubmitted({
        FNAME: firstName,
        LNAME: lastName,
        EMAIL: email,
        MMERGE6: company,
      })
  }

  useEffect(() => {
    if (props.status === 'success') {
      props.onSubscribe()
    }
  }, [props.status])

  const validateRequiredFieldOnBlur = (value, errorKey): void => {
    if (!value) {
      setErrors(
        Object.assign({}, errors, { [errorKey]: 'This field is required' })
      )
    } else {
      setErrors(Object.assign({}, errors, { [errorKey]: '' }))
    }
  }

  const validateEmail = (value): void => {
    let errorMessage = ''
    if (!value) {
      errorMessage = 'This field is required'
    } else if (!isEmail(value)) {
      errorMessage = 'Invalid email address'
    }
    setErrors(Object.assign({}, errors, { email: errorMessage }))
  }

  return (
    <React.Fragment>
      <FormContainer>
        <Row style={rowStyles}>
          <Input
            onChange={(e): void => setFirstName(e.target.value)}
            placeholder="First name*"
            errorMessage={errors.firstName}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'firstName')
            }
          />
          <Separator />
          <Input
            onChange={(e): void => setLastName(e.target.value)}
            placeholder="Last name*"
            errorMessage={errors.lastName}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'lastName')
            }
          />
        </Row>
        <Row style={rowStyles}>
          <Input
            onChange={(e): void => setEmail(e.target.value)}
            placeholder="Email*"
            errorMessage={errors.email}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateEmail(e.target.value)
            }
          />
        </Row>
        <Row style={rowStyles}>
          <Input
            onChange={(e): void => setCompany(e.target.value)}
            placeholder="Company (optional)"
          />
        </Row>
        <RequiredFieldLabel>*Mandatory fields</RequiredFieldLabel>
      </FormContainer>

      {props.status === 'error' && (
        <StyledMessage dangerouslySetInnerHTML={{ __html: props.message }} />
      )}

      <Button style={buttonStyle} onClick={submit}>
        {props.status === 'sending' ? (
          <Spinner style={spinnerStyle} />
        ) : (
          <span>Subscribe</span>
        )}
      </Button>
    </React.Fragment>
  )
}

interface Props {
  onSubscribe: () => void
}

const NewsletterForm = (props: Props): JSX.Element => {
  return (
    <React.Fragment>
      <StyledHeader> Get the latest news</StyledHeader>
      <div>
        Get business advice, resources and the latest news from Pacific Business
        Trust and our Service Provider network delivered straight to your inbox.
        Sign up for our newsletter below.
      </div>
      <Squiggle src={largeSquiggle} alt="Squiggle" />

      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={({ subscribe, status, message }): ReactNode => (
          <Form
            status={status}
            message={message}
            onSubmitted={(formData): void => subscribe(formData)}
            onSubscribe={props.onSubscribe}
          />
        )}
      />
    </React.Fragment>
  )
}

export default NewsletterForm
